import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// import Analytics from '../components/Template/Analytics';
import Navigation from '../components/Template/Navigation';
import SideBar from '../components/Template/SideBar';
import ScrollToTop from '../components/Template/ScrollToTop';

const Main = (props) => (
  <>
    {/* <Analytics /> */}
    <ScrollToTop />
    <div id="wrapper">
      <Navigation />
      <div id="main">
        <motion.div
          className="container text-center bg-black"
          key={props.title}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Define metadata within the component */}
          <article>
            <title>{props.title ? `${props.title} | Brandon Lu` : 'Brandon Lu'}</title>
            <meta name="description" content={props.description || 'Default description'} />
          </article>
          {props.children}
        </motion.div>
      </div>
      {!props.fullPage && <SideBar />}
    </div>
  </>
);

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullPage: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

Main.defaultProps = {
  children: null,
  fullPage: false,
  title: null,
  description: "Brandon Lu's personal website.",
};

export default Main;
